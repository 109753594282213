import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { SNACKBARTYPE, useSnackbar } from '../../../../components/AppSnackbar';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  adyenPaymentsGeneralSettingsValidationSchema,
  AdyenPaymentsGeneralSettingsValidationSchemaType,
} from '../../utils/validation.utils';
import AppButton from '../../../../components/AppButton';
import BasicInformation from './BasicInformation';
import ContactInformation from './ContactInformation';
import { selectAuth } from '../../../../redux/selectors/authSelectors';
import { UpdateGeneralSettingsDTO } from '../../types/general-settings.types';
import { updateGeneralSettingsData } from '../../redux/generalSettingsSlice';
import { getProfile, getRestaurant } from '../../../auth/redux/authSlice';

const AdyenPaymentsGeneralSettingsPageView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { restaurant, isLoadingRestaurant } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();

  const form = useForm<AdyenPaymentsGeneralSettingsValidationSchemaType>({
    resolver: yupResolver(adyenPaymentsGeneralSettingsValidationSchema),
    defaultValues: {
      name: '',
      address: '',
      country: '',
      city: '',
      timeZone: '',
      postalCode: '',
      currency: '',

      email: '',
      number: '',
      pocName: '',
      pocNumber: '',
    },
  });

  const setDefaultFormValues = useCallback(() => {
    if (restaurant) {
      form.setValue('name', restaurant.name);
      form.setValue('address', restaurant.address);
      form.setValue('country', restaurant.posCountry.toString());
      form.setValue('city', restaurant.posCity.toString());
      form.setValue('timeZone', restaurant.posTimeZone);
      form.setValue('postalCode', restaurant.posPostalCode);
      form.setValue('currency', `Singapore Dollar (SGD)`);

      form.setValue('email', restaurant.registeredEmail);
      form.setValue('number', restaurant.registeredMobileNo);
      form.setValue('pocName', restaurant.contactPersonName);
      form.setValue('pocNumber', restaurant.contactPersonMobileNo);
    }
  }, [restaurant, form]);

  useEffect(() => {
    setDefaultFormValues();
  }, [setDefaultFormValues]);

  const handleOnCancel = () => {
    navigate('/settings');
  };

  const onSubmit = form.handleSubmit(async (values) => {
    const payload: UpdateGeneralSettingsDTO = {
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,

      registeredEmail: values.email,
      registeredMobileNo: values.number,
      contactPersonName: values.pocName,
      contactPersonMobileNo: values.pocNumber,
    };

    const response = await dispatch(updateGeneralSettingsData(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      openSnackbar(
        'General Settings updated successfully!',
        SNACKBARTYPE.SUCCESS,
      );
      await dispatch(getRestaurant());
      await dispatch(getProfile());
      navigate('/settings/general');
    }
  });

  return (
    <Box
      mr={'25%'}
      sx={{
        height: '50vh',
        width: '100%',
        position: 'relative',
      }}>
      {isLoadingRestaurant && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <FormProvider {...form}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            width: '100%',
          }}
          component={'form'}
          onSubmit={onSubmit}>
          <BasicInformation />

          <ContactInformation />

          <Stack direction="row-reverse" sx={{ mt: 2, pb: 3 }}>
            <AppButton
              type="submit"
              size="large"
              title="Save"
              variant="contained"
              loading={form.formState.isSubmitting}
              sx={{
                ml: 2,
              }}
            />

            <AppButton
              type="button"
              size="large"
              title="Cancel"
              color="secondary"
              variant="contained"
              onClick={handleOnCancel}
            />
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AdyenPaymentsGeneralSettingsPageView;
