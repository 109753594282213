import { Controller, useFormContext } from 'react-hook-form';
import ViewLayout from '../../hocs/ViewLayout';
import AppTextInput from '../../../../components/AppTextInput';
import { AdyenPaymentsGeneralSettingsValidationSchemaType } from '../../utils/validation.utils';
import Stack from '@mui/material/Stack';

const BasicInformation = () => {
  const form =
    useFormContext<AdyenPaymentsGeneralSettingsValidationSchemaType>();

  return (
    <ViewLayout title={'Restaurant Information'} DividerNotRequire={true}>
      <Controller
        name="name"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            label="Name"
            placeholder="E.g: Taste of India"
            {...field}
            error={form.formState.errors.name?.message}
            onBlur={field.onBlur}
            data-testid="Restaurant Name"
          />
        )}
      />

      <Controller
        name="address"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            multiline
            label="Street"
            placeholder="E.g: Street 01"
            {...field}
            error={form.formState.errors.address?.message}
            onBlur={field.onBlur}
            data-testid="Address"
          />
        )}
      />

      <Stack direction="row" spacing={2}>
        <Controller
          name="country"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              disabled
              label="Country"
              placeholder="E.g: Singapore"
              {...field}
              error={form.formState.errors.country?.message}
              onBlur={field.onBlur}
              data-testid="Country"
            />
          )}
        />

        <Controller
          name="city"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              disabled
              label="City"
              placeholder="E.g: Little India"
              {...field}
              error={form.formState.errors.city?.message}
              onBlur={field.onBlur}
              data-testid="City"
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Controller
          name="timeZone"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              disabled
              label="Time Zone"
              placeholder="E.g: UTC+8"
              {...field}
              error={form.formState.errors.timeZone?.message}
              onBlur={field.onBlur}
              data-testid="Time Zone"
            />
          )}
        />

        <Controller
          name="postalCode"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              label="Postal Code"
              placeholder="E.g: 520147"
              {...field}
              error={form.formState.errors.postalCode?.message}
              onBlur={field.onBlur}
              data-testid="Postal Code"
            />
          )}
        />
      </Stack>
      <Controller
        name="currency"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            label="Currency"
            placeholder="Currency"
            {...field}
            error={form.formState.errors.currency?.message}
            onBlur={field.onBlur}
            data-testid="Currency"
          />
        )}
      />
    </ViewLayout>
  );
};

export default BasicInformation;
