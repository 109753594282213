import * as yup from 'yup';
import YupPhone from '../../../utils/YupPhone';
import { checkMinImageDimensions } from '../../../utils';
import { ADYEN_LEGAL_ENTITY_TYPES } from '../../../constants';

YupPhone(yup);

export const stripePosGeneralSettingsValidationSchema = yup.object({
  // Basic information
  name: yup
    .string()
    .trim()
    .required('Restaurant Name is required. Please fill the field'),
  address: yup
    .string()
    .trim()
    .required('Address is required. Please fill the field'),
  country: yup.string().trim(),
  city: yup.string().trim(),
  timeZone: yup.string().trim(),
  postalCode: yup
    .string()
    .trim()
    .required('Postal Code is required')
    .test('postal-code', 'Invalid postal code format', function (value) {
      if (!value) return false;

      const country = this.parent.country;

      switch (country) {
        case 'Singapore':
          return /^\d{6}$/.test(value);
        case 'Australia':
          return /^\d{4}$/.test(value);
        case 'Sri Lanka':
          return /^\d{5}$/.test(value);
        default:
          return /^[a-zA-Z\d]+$/.test(value); // fallback to alphanumeric validation
      }
    }),
  currency: yup.string().trim(),
  image: yup
    .mixed<File[]>()
    .test(
      'is-image',
      'Invalid image file format, should be jpg, jpeg or png',
      (value) => {
        if (!value || value.length === 0) {
          return true; // Allow empty values
        }

        const fileArray: File[] = [];
        for (const element of value) {
          fileArray.push(element);
        }
        const fileExtensions = ['jpg', 'jpeg', 'png'];
        return fileArray.every((file) =>
          fileExtensions.includes(
            file.name.split('.').pop()?.toLowerCase() as string,
          ),
        );
      },
    )
    .test(
      'check-image-dimensions',
      'Image dimensions are incorrect',
      async (value) => {
        if (value?.length) {
          return (
            await Promise.all(
              value.map((file) =>
                checkMinImageDimensions(file, { width: 1024, height: 1024 }),
              ),
            )
          ).every((res) => res);
        }
        return true;
      },
    ),

  // Location information
  location: yup.string().trim(),
  latitude: yup.string().trim(),
  longitude: yup.string().trim(),

  // Contact information
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required. Please fill the field'),
  number: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),
  pocName: yup
    .string()
    .trim()
    .required('Point of contact name is required. Please fill the field'),
  pocNumber: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),

  // Company information
  companyName: yup
    .string()
    .trim()
    .required('Company Name is required. Please fill the field'),
  registeredAddress: yup
    .string()
    .trim()
    .required('Address is required. Please fill the field'),
  UEN: yup.string().trim().required('UEN is required. Please fill the field'),
});

export const stripePaymentsGeneralSettingsValidationSchema = yup.object({
  // Basic information
  name: yup
    .string()
    .trim()
    .required('Restaurant Name is required. Please fill the field'),
  address: yup
    .string()
    .trim()
    .required('Address is required. Please fill the field'),
  country: yup.string().trim(),
  city: yup.string().trim(),
  timeZone: yup.string().trim(),
  postalCode: yup
    .string()
    .trim()
    .required('Postal Code is required')
    .test('postal-code', 'Invalid postal code format', function (value) {
      if (!value) return false;

      const country = this.parent.country;

      switch (country) {
        case 'Singapore':
          return /^\d{6}$/.test(value);
        case 'Australia':
          return /^\d{4}$/.test(value);
        case 'Sri Lanka':
          return /^\d{5}$/.test(value);
        default:
          return /^[a-zA-Z\d]+$/.test(value); // fallback to alphanumeric validation
      }
    }),
  currency: yup.string().trim(),

  // Contact information
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required. Please fill the field'),
  number: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),
  pocName: yup
    .string()
    .trim()
    .required('Point of contact name is required. Please fill the field'),
  pocNumber: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),

  // Company information
  companyName: yup
    .string()
    .trim()
    .required('Company Name is required. Please fill the field'),
  registeredAddress: yup
    .string()
    .trim()
    .required('Address is required. Please fill the field'),
  UEN: yup.string().required('UEN is required. Please fill the field'),
});

export const adyenPaymentsGeneralSettingsValidationSchema = yup.object({
  // Basic information
  name: yup
    .string()
    .trim()
    .required('Restaurant Name is required. Please fill the field'),
  address: yup
    .string()
    .trim()
    .required('Address is required. Please fill the field'),
  country: yup.string().trim(),
  city: yup.string().trim(),
  timeZone: yup.string().trim(),
  postalCode: yup
    .string()
    .trim()
    .required('Postal Code is required')
    .test('postal-code', 'Invalid postal code format', function (value) {
      if (!value) return false;

      const country = this.parent.country;

      switch (country) {
        case 'Singapore':
          return /^\d{6}$/.test(value);
        case 'Australia':
          return /^\d{4}$/.test(value);
        case 'Sri Lanka':
          return /^\d{5}$/.test(value);
        default:
          return /^[a-zA-Z\d]+$/.test(value); // fallback to alphanumeric validation
      }
    }),
  currency: yup.string().trim(),

  // Contact information
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required. Please fill the field'),
  number: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),
  pocName: yup
    .string()
    .trim()
    .required('Point of contact name is required. Please fill the field'),
  pocNumber: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone is required. Please fill the field'),
});

export const accountLegalEntityValidationSchema = yup.object({
  companyName: yup
    .string()
    .trim()
    .required('Company Name is required. Please fill the field'),
  type: yup.string().oneOf(Object.values(ADYEN_LEGAL_ENTITY_TYPES)),
  registrationNumber: yup
    .string()
    .trim()
    .required('Registration Number is required. Please fill the field'),
  firstName: yup.string().when('type', {
    is: ADYEN_LEGAL_ENTITY_TYPES.SOLE_PROPRIETORSHIP,
    then: (schema) =>
      schema.required('First Name is required. Please fill the field'),
  }),
  lastName: yup.string().when('type', {
    is: ADYEN_LEGAL_ENTITY_TYPES.SOLE_PROPRIETORSHIP,
    then: (schema) =>
      schema.required('Last Name is required. Please fill the field'),
  }),

  registeredAddress: yup.object({
    street: yup
      .string()
      .trim()
      .required('Street is required. Please fill the field'),
    city: yup
      .string()
      .trim()
      .required('City is required. Please fill the field'),
    country: yup
      .string()
      .trim()
      .required('Country is required. Please fill the field'),
    postalCode: yup
      .string()
      .trim()
      .required('Postal Code is required')
      .test('postal-code', 'Invalid postal code format', function (value) {
        if (!value) return false;

        const country = this.parent.country;

        switch (country) {
          case 'Singapore':
            return /^\d{6}$/.test(value);
          case 'Australia':
            return /^\d{4}$/.test(value);
          case 'Sri Lanka':
            return /^\d{5}$/.test(value);
          default:
            return /^[a-zA-Z\d]+$/.test(value); // fallback to alphanumeric validation
        }
      }),
  }),
});

export const adyenBusinessInformationValidationSchema = yup.object({
  webUrl: yup.string(),
  shopperStatement: yup.string(),
});

export type StripePosGeneralSettingsValidationSchemaType = yup.InferType<
  typeof stripePosGeneralSettingsValidationSchema
>;

export type StripePaymentsGeneralSettingsValidationSchemaType = yup.InferType<
  typeof stripePaymentsGeneralSettingsValidationSchema
>;

export type AdyenPaymentsGeneralSettingsValidationSchemaType = yup.InferType<
  typeof adyenPaymentsGeneralSettingsValidationSchema
>;

export type AccountLegalEntityValidationSchemaType = yup.InferType<
  typeof accountLegalEntityValidationSchema
>;

export type AdyenBusinessInformationValidationSchemaType = yup.InferType<
  typeof adyenBusinessInformationValidationSchema
>;
