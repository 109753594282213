import { Controller, useFormContext } from 'react-hook-form';
import ViewLayout from '../../hocs/ViewLayout';
import { StripePaymentsGeneralSettingsValidationSchemaType } from '../../utils/validation.utils';
import AppTextInput from '../../../../components/AppTextInput';

const CompanyInformation = () => {
  const form =
    useFormContext<StripePaymentsGeneralSettingsValidationSchemaType>();

  return (
    <ViewLayout title={'Company Information'} DividerNotRequire={true}>
      <Controller
        name="companyName"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            label="Company Name"
            placeholder="E.g: Taste of India"
            {...field}
            error={form.formState.errors.companyName?.message}
            onBlur={field.onBlur}
            data-testid="Company Name"
          />
        )}
      />

      <Controller
        name="registeredAddress"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            multiline
            label="Registered Address"
            placeholder="Address Line 01"
            {...field}
            error={form.formState.errors.registeredAddress?.message}
            onBlur={field.onBlur}
            data-testid="Registered Address"
          />
        )}
      />

      <Controller
        name="UEN"
        control={form.control}
        render={({ field }) => (
          <AppTextInput
            label="UEN"
            placeholder="E.g 876543527"
            {...field}
            error={form.formState.errors.UEN?.message}
            onBlur={field.onBlur}
            data-testid="UEN"
          />
        )}
      />
    </ViewLayout>
  );
};

export default CompanyInformation;
