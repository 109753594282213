import { ORDER_TYPE, RESTAURANT_PAYMENT_PROVIDER } from '../../../constants';
import { RestaurantResponseDTO } from '../../auth/types/auth.types';
import { GENERAL_SETTINGS_VIEW } from '../constants';
import { OrderTypesResponseDTO } from '../types/order-types.types';

export const checkOrderTypeAvailability = (
  orderTypesData: OrderTypesResponseDTO,
  orderType: ORDER_TYPE,
) => {
  return orderTypesData?.enabledOrderTypes?.includes(orderType);
};

/**
 * @function getGeneralSettingsViewType
 * @description A function that determines which general settings page to render
 * based on the provided restaurant object and whether the restaurant is loading.
 * @param {Object} args - An object containing the restaurant object and
 * a boolean indicating whether the restaurant is loading.
 * @param {RestaurantResponseDTO} [args.restaurant] - The restaurant object.
 * @param {boolean} [args.isLoading] - A boolean indicating whether the
 * restaurant is loading.
 * @defaults isLoading - false
 * @returns {GENERAL_SETTINGS_VIEW} - The type of general settings page to render.
 */
export const getGeneralSettingsViewType = ({
  restaurant,
  isLoading = false,
}: {
  restaurant?: RestaurantResponseDTO;
  isLoading?: boolean;
}): GENERAL_SETTINGS_VIEW | undefined => {
  if (!isLoading && !restaurant) {
    return undefined;
  }

  if (isLoading) {
    return GENERAL_SETTINGS_VIEW.LOADING;
  }

  if (restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.STRIPE) {
    if (restaurant?.paymentsOnly) {
      return GENERAL_SETTINGS_VIEW.STRIPE_PAYMENTS;
    } else {
      return GENERAL_SETTINGS_VIEW.STRIPE_POS;
    }
  } else {
    return GENERAL_SETTINGS_VIEW.ADYEN_PAYMENTS;
  }
};
