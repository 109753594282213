import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import AppButton from '../../../components/AppButton';
import { Colors } from '../../../theme/colors';
import { ReactNode, useMemo } from 'react';

type Props = {
  title: string;
  description?: string;
  children: React.ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  button?: ReactNode;
  DividerNotRequire?: boolean;
};

function ViewLayout({
  title,
  description,
  children,
  buttonText,
  onButtonClick,
  button,
  DividerNotRequire,
}: Readonly<Props>) {
  const actionButton = useMemo(() => {
    if (button) {
      return button;
    } else if (buttonText) {
      return (
        <AppButton
          variant="contained"
          onClick={onButtonClick}
          size="medium"
          startIcon={<AddIcon />}>
          {buttonText}
        </AppButton>
      );
    } else {
      return null;
    }
  }, [button, buttonText, onButtonClick]);

  return (
    <Box
      sx={{
        flex: 3,
        borderRadius: 2,
        border: 1,
        borderColor: Colors.borderPrimary,
        py: 2,
        px: 3,
        mb: 2,
        ml: -5,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}>
        <Box>
          <Typography variant="h5" fontWeight={700} mb={1}>
            {title}
          </Typography>

          {description && (
            <Typography
              variant="body2"
              sx={{
                color: Colors.fontColorSecondary,
                mb: 3,
                fontSize: '14px',
                fontWeight: 400,
              }}>
              {description}
            </Typography>
          )}
        </Box>

        {actionButton}
      </Box>
      {!DividerNotRequire ? <Divider /> : null}

      <Box> {children} </Box>
    </Box>
  );
}

export default ViewLayout;
