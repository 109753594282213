import {
  GoogleMap,
  Libraries,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import ViewLayout from '../../hocs/ViewLayout';
import { StripePosGeneralSettingsValidationSchemaType } from '../../utils/validation.utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import AppTextInput from '../../../../components/AppTextInput';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import AppCircularLoader from '../../../../components/AppCircularLoader';

const libraries: Libraries | undefined = ['places'];

const LocationInformation = () => {
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });

  const form = useFormContext<StripePosGeneralSettingsValidationSchemaType>();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: String(import.meta.env.VITE_APP_GOOGLE_MAP_API_KEY),
    libraries,
  });

  const latitudeWatch = useWatch({
    name: 'latitude',
    control: form.control,
  });
  const longitudeWatch = useWatch({
    name: 'longitude',
    control: form.control,
  });

  useEffect(() => {
    if (isLoaded) {
      const latitude = Number(latitudeWatch);
      const longitude = Number(longitudeWatch);

      setMarkerPosition({
        lat: !isNaN(latitude) ? latitude : 0,
        lng: !isNaN(longitude) ? longitude : 0,
      });
    }
  }, [isLoaded, latitudeWatch, longitudeWatch]);

  if (!isLoaded) {
    return <AppCircularLoader />;
  }

  return (
    <ViewLayout title={'Location Information'} DividerNotRequire={true}>
      <Box
        sx={{
          height: '300px',
          width: '100%',
          mb: 2,
          borderRadius: '10px',
          overflow: 'hidden',
        }}>
        <GoogleMap
          zoom={17}
          center={markerPosition}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            gestureHandling: 'cooperative',
          }}>
          {markerPosition.lat !== 0 && markerPosition.lng !== 0 ? (
            <Marker position={markerPosition} visible />
          ) : null}
        </GoogleMap>
      </Box>

      <Stack direction="row" spacing={2}>
        <Controller
          disabled
          name="latitude"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              disabled
              label="Latitude"
              placeholder="Latitude"
              {...field}
              error={form.formState.errors.latitude?.message}
              onBlur={field.onBlur}
              data-testid="Latitude"
            />
          )}
        />

        <Controller
          disabled
          name="longitude"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              disabled
              label="Longitude"
              placeholder="Longitude"
              {...field}
              error={form.formState.errors.longitude?.message}
              onBlur={field.onBlur}
              data-testid="Longitude"
            />
          )}
        />
      </Stack>
    </ViewLayout>
  );
};

export default LocationInformation;
