import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { SNACKBARTYPE, useSnackbar } from '../../../../components/AppSnackbar';
import { useCallback, useEffect, useState } from 'react';
import { updateGeneralSettingsData } from '../../redux/generalSettingsSlice';
import last from 'lodash/last';
import { createS3Image } from '../../../menu/redux/imageSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { UpdateGeneralSettingsDTO } from '../../types/general-settings.types';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AppButton from '../../../../components/AppButton';
import {
  stripePosGeneralSettingsValidationSchema,
  StripePosGeneralSettingsValidationSchemaType,
} from '../../utils/validation.utils';
import { selectAuth } from '../../../../redux/selectors/authSelectors';
import BasicInformation from './BasicInformation';
import LocationInformation from './LocationInformation';
import CompanyInformation from './CompanyInformation';
import ContactInformation from './ContactInformation';
import { getProfile, getRestaurant } from '../../../auth/redux/authSlice';

const StripePosGeneralSettingsPageView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { restaurant, isLoadingRestaurant } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();

  const [initialFormValues, setInitialFormValues] =
    useState<StripePosGeneralSettingsValidationSchemaType | null>(null);

  const getImageUrl = useCallback(
    async (uploadableImage?: File) => {
      if (!uploadableImage) {
        return null;
      }

      const imageName = uploadableImage.name.split('.')[0];
      const currentImageName = last(restaurant?.logo?.split('/'));

      if (imageName === currentImageName) {
        return restaurant?.logo;
      }

      return (
        await dispatch(
          createS3Image({
            file: uploadableImage,
          }),
        )
      ).payload;
    },
    [dispatch, restaurant?.logo],
  );

  const form = useForm<StripePosGeneralSettingsValidationSchemaType>({
    resolver: yupResolver(stripePosGeneralSettingsValidationSchema),
    defaultValues: {
      name: '',
      address: '',
      country: '',
      city: '',
      timeZone: '',
      currency: '',
      postalCode: '',
      companyName: '',
      registeredAddress: '',
      UEN: '',
      email: '',
      number: '',
      pocName: '',
      pocNumber: '',
      location: '',
      image: [],
      longitude: '',
      latitude: '',
    },
  });

  const onSubmit = form.handleSubmit(
    async (values: StripePosGeneralSettingsValidationSchemaType) => {
      const imageUrl = await getImageUrl(values.image?.[0]);

      const payload: UpdateGeneralSettingsDTO = {
        name: values.name,
        logo: imageUrl,
        address: values.address,
        postalCode: values.postalCode,
        merchantName: values.companyName,
        registeredAddress: values.registeredAddress,
        registeredEmail: values.email,
        registeredMobileNo: values.number,
        contactPersonName: values.pocName,
        contactPersonMobileNo: values.pocNumber,
        registeredNumber: values.UEN,
      };

      const response = await dispatch(updateGeneralSettingsData(payload));

      if (response.meta.requestStatus === 'fulfilled') {
        openSnackbar(
          'General Settings updated successfully!',
          SNACKBARTYPE.SUCCESS,
        );
        await dispatch(getRestaurant());
        await dispatch(getProfile());
        navigate('/settings/general');
      }
    },
  );

  useEffect(() => {
    const setFormValues = async () => {
      if (restaurant) {
        const imageUrl = restaurant.logo;

        let imageData = null;

        if (imageUrl) {
          try {
            const response = await axios.get(imageUrl, {
              responseType: 'blob',
            });

            imageData = new File(
              [response.data],
              `${
                imageUrl.split('/')[imageUrl.split('/').length - 1] ?? 'image'
              }.jpeg`,
              {
                type: 'image/jpeg',
              },
            );
          } catch {
            imageData = null;
          }
        }

        const {
          name,
          address,
          posPostalCode,
          latitude,
          longitude,
          merchantName,
          registeredNumber,
          registeredAddress,
          registeredEmail,
          registeredMobileNo,
          contactPersonName,
          contactPersonMobileNo,
          posCountry,
          posCity,
          posTimeZone,
        } = restaurant;

        const initialData: StripePosGeneralSettingsValidationSchemaType = {
          name: name,
          address: address,
          country: posCountry,
          city: posCity,
          timeZone: posTimeZone,
          currency: `Singapore Dollar (SGD)`,
          postalCode: posPostalCode,
          companyName: merchantName,
          registeredAddress: registeredAddress,
          UEN: registeredNumber,
          email: registeredEmail,
          number: registeredMobileNo,
          pocName: contactPersonName,
          pocNumber: contactPersonMobileNo,
          location: address,
          latitude: latitude,
          longitude: longitude,
          image: imageData ? [imageData] : [],
        };

        Object.keys(initialData).forEach((key) => {
          form.setValue(
            key as keyof typeof initialData,
            initialData[key as keyof typeof initialData],
          );
        });
        setInitialFormValues(initialData);
      }
    };

    setFormValues();
  }, [restaurant, form.reset, form]);

  const handleOnCancel = () => {
    if (initialFormValues) {
      form.reset(initialFormValues);
    }
  };

  return (
    <Box
      mr={'25%'}
      sx={{
        height: '50vh',
        width: '100%',
        position: 'relative',
      }}>
      {isLoadingRestaurant && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <FormProvider {...form}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            width: '100%',
          }}
          component={'form'}
          onSubmit={onSubmit}>
          <BasicInformation />

          <LocationInformation />

          <ContactInformation />

          <CompanyInformation />

          <Stack direction="row-reverse" sx={{ mt: 2, pb: 3 }}>
            <AppButton
              type="submit"
              size="large"
              title="Save"
              variant="contained"
              loading={form.formState.isSubmitting}
              sx={{
                ml: 2,
              }}
            />

            <AppButton
              type="button"
              size="large"
              title="Cancel"
              color="secondary"
              variant="contained"
              onClick={handleOnCancel}
            />
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default StripePosGeneralSettingsPageView;
