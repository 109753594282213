import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { SNACKBARTYPE, useSnackbar } from '../../../../components/AppSnackbar';
import {
  stripePaymentsGeneralSettingsValidationSchema,
  StripePaymentsGeneralSettingsValidationSchemaType,
  StripePosGeneralSettingsValidationSchemaType,
} from '../../utils/validation.utils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppButton from '../../../../components/AppButton';
import { selectAuth } from '../../../../redux/selectors/authSelectors';
import { updateGeneralSettingsData } from '../../redux/generalSettingsSlice';
import { UpdateGeneralSettingsDTO } from '../../types/general-settings.types';
import LocationInformation from '../StripePosGeneralSettingsPageView/LocationInformation';
import BasicInformation from './BasicInformation';
import CompanyInformation from './CompanyInformation';
import ContactInformation from './ContactInformation';
import { getProfile, getRestaurant } from '../../../auth/redux/authSlice';

const StripePaymentsGeneralSettingsPageView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { restaurant, isLoadingRestaurant } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();

  const [initialFormValues, setInitialFormValues] =
    useState<StripePaymentsGeneralSettingsValidationSchemaType | null>(null);

  const form = useForm<StripePaymentsGeneralSettingsValidationSchemaType>({
    resolver: yupResolver(stripePaymentsGeneralSettingsValidationSchema),
    defaultValues: {
      name: '',
      address: '',
      country: '',
      city: '',
      timeZone: '',
      postalCode: '',
      currency: '',

      email: '',
      number: '',
      pocName: '',
      pocNumber: '',

      companyName: '',
      registeredAddress: '',
      UEN: '',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    const payload: UpdateGeneralSettingsDTO = {
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,

      merchantName: values.companyName,
      registeredNumber: values.UEN,
      registeredAddress: values.registeredAddress,

      registeredEmail: values.email,
      registeredMobileNo: values.number,
      contactPersonName: values.pocName,
      contactPersonMobileNo: values.pocNumber,
    };

    const response = await dispatch(updateGeneralSettingsData(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      openSnackbar(
        'General Settings updated successfully!',
        SNACKBARTYPE.SUCCESS,
      );
      await dispatch(getRestaurant());
      await dispatch(getProfile());
      navigate('/settings/general');
    }
  });

  useEffect(() => {
    if (restaurant) {
      const {
        name,
        address,
        posPostalCode,
        merchantName,
        registeredNumber,
        registeredAddress,
        registeredEmail,
        registeredMobileNo,
        contactPersonName,
        contactPersonMobileNo,
        posCountry,
        posCity,
        posTimeZone,
      } = restaurant;

      const initialData: StripePosGeneralSettingsValidationSchemaType = {
        name: name,
        address: address,
        country: String(posCountry),
        city: String(posCity),
        timeZone: posTimeZone,
        currency: `Singapore Dollar (SGD)`,
        postalCode: posPostalCode,
        companyName: merchantName,
        registeredAddress: registeredAddress,
        UEN: registeredNumber,
        email: registeredEmail,
        number: registeredMobileNo,
        pocName: contactPersonName,
        pocNumber: contactPersonMobileNo,
      };
      setInitialFormValues(initialData);
      form.reset(initialData);
    }
  }, [restaurant, form.reset, form]);

  const handleOnCancel = () => {
    if (initialFormValues) {
      form.reset(initialFormValues);
    }
  };

  return (
    <Box
      mr={'25%'}
      sx={{
        height: '50vh',
        width: '100%',
        position: 'relative',
      }}>
      {isLoadingRestaurant && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <FormProvider {...form}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            width: '100%',
          }}
          component={'form'}
          onSubmit={onSubmit}>
          <BasicInformation />

          <LocationInformation />

          <ContactInformation />

          <CompanyInformation />

          <Stack direction="row-reverse" sx={{ mt: 2, pb: 3 }}>
            <AppButton
              type="submit"
              size="large"
              title="Save"
              variant="contained"
              loading={form.formState.isSubmitting}
              sx={{
                ml: 2,
              }}
            />

            <AppButton
              type="button"
              size="large"
              title="Cancel"
              color="secondary"
              variant="contained"
              onClick={handleOnCancel}
            />
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default StripePaymentsGeneralSettingsPageView;
