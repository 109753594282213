import { Controller, useFormContext } from 'react-hook-form';

import { StripePaymentsGeneralSettingsValidationSchemaType } from '../../utils/validation.utils';
import ViewLayout from '../../hocs/ViewLayout';
import Stack from '@mui/material/Stack';
import AppTextInput from '../../../../components/AppTextInput';
import AppPhoneInput from '../../../../components/AppPhoneInput';

const ContactInformation = () => {
  const form =
    useFormContext<StripePaymentsGeneralSettingsValidationSchemaType>();

  return (
    <ViewLayout title={'Contact Information'} DividerNotRequire={true}>
      <Stack direction="row" spacing={2}>
        <Controller
          name="email"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              label="Email Address"
              placeholder="E.g: yours@example.com"
              {...field}
              error={form.formState.errors.email?.message}
              onBlur={field.onBlur}
              data-testid="Email Address"
            />
          )}
        />

        <Controller
          name="number"
          control={form.control}
          render={({ field }) => (
            <AppPhoneInput
              label="Phone Number"
              placeholder="E.g: 9999999999"
              {...field}
              onChange={(value) => {
                form.setValue('number', value, {
                  shouldValidate: true,
                });
              }}
              value={field.value}
              error={form.formState.errors.number?.message}
              onBlur={field.onBlur}
              data-testid="Phone Number"
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Controller
          name="pocName"
          control={form.control}
          render={({ field }) => (
            <AppTextInput
              label="Point of Contact Name"
              placeholder="E.g: John Doe"
              {...field}
              error={form.formState.errors.pocName?.message}
              onBlur={field.onBlur}
              data-testid="Point of Contact Name"
            />
          )}
        />

        <Controller
          name="pocNumber"
          control={form.control}
          render={({ field }) => (
            <AppPhoneInput
              label="Point of Contact Phone Number"
              placeholder="E.g: 9999999999"
              {...field}
              onChange={(value) => {
                form.setValue('pocNumber', value, {
                  shouldValidate: true,
                });
              }}
              value={field.value}
              error={form.formState.errors.pocNumber?.message}
              onBlur={field.onBlur}
              data-testid="Point of Contacts Number"
            />
          )}
        />
      </Stack>
    </ViewLayout>
  );
};

export default ContactInformation;
