import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const AppCircularLoader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        zIndex: 1000,
      }}>
      <CircularProgress size="25px" thickness={5} />
    </Box>
  );
};

export default AppCircularLoader;
