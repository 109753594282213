import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { GENERAL_SETTINGS_VIEW } from '../constants';
import { getGeneralSettingsViewType } from '../utils';
import AdyenPaymentsGeneralSettingsPageView from '../views/AdyenPaymentsGeneralSettingsPageView';
import StripePaymentsGeneralSettingsPageView from '../views/StripePaymentsGeneralSettingsPageView';
import StripePosGeneralSettingsPageView from '../views/StripePosGeneralSettingsPageView';
import { alpha } from '@mui/material';
import { Colors } from '../../../theme/colors';

const GeneralSettingsPage = () => {
  const { restaurant, isChangingRestaurant } = useAppSelector(selectAuth);

  const viewType = getGeneralSettingsViewType({
    restaurant,
    isLoading: isChangingRestaurant,
  });

  switch (viewType) {
    case GENERAL_SETTINGS_VIEW.STRIPE_PAYMENTS:
      return <StripePaymentsGeneralSettingsPageView />;
    case GENERAL_SETTINGS_VIEW.STRIPE_POS:
      return <StripePosGeneralSettingsPageView />;
    case GENERAL_SETTINGS_VIEW.ADYEN_PAYMENTS:
      return <AdyenPaymentsGeneralSettingsPageView />;
    default:
      return (
        <Box
          mr={'25%'}
          sx={{
            height: '50vh',
            width: '100%',
            position: 'relative',
          }}>
          <Backdrop
            open={true}
            sx={{
              position: 'absolute',
              zIndex: 999,
              backgroundColor: alpha(Colors.backgroundSecondary, 0.5),
            }}>
            <CircularProgress size="25px" thickness={5} />
          </Backdrop>
        </Box>
      );
  }
};

export default GeneralSettingsPage;
